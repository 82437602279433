// THIS IS HOW TO DO SOMETHING IN JS *AFTER* THE SCRIPTS ARE LOADED
document.addEventListener('vite-script-loaded', function (e) {
    if (e.detail.path === 'src/js/app.ts') {
        // The script with the path src/js/app.ts is now loaded, do whatever initialization is needed
    }
});

import $ from 'jquery';

$(".js-toggle-search, [data-js-toggle-search]").on("click", function (event) {
  event.preventDefault();
  $(".header__search").toggleClass("active");
  $(".js-toggle-search__icons").find("span").toggle();
});

$('.js-toggle-sites').on('click', function() {
	$('.header__mobile-menu__sites__menu').toggle();
	$(this).toggleClass('active');
});

$('.header__mobile-menu__main-menu .header__mobile-menu__button').on('click', function() {
	$(this).parent().find('.header__mobile-menu__main-menu__subnav').toggle();
	$(this).toggleClass('active');
});

$('.header__mobile-menu__main-menu__subnav__back').on('click', function() {
	$(this).parent().toggle();
	$(this).parent().parent().removeClass('active');
});

$('.js-toggle-mobile-menu').on('click', function() {
	$('.header__mobile-menu__menu').toggle();
	$(this).find('span').toggle();
});

$(".header__main-menu li").on({
    mouseenter: function () {
        $('main').addClass('active');
    },
    mouseleave: function () {
        $('main').removeClass('active');
    }
});

$('.js-toggle-mailchimp-form').on('click', function() {
	$('.js-mailchimp-form').slideToggle();
});