function getReferrerDomain() {
  var url = document.createElement("a");
  url.href = document.referrer;
  return url.hostname;
}

export function setReferrerCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

// if the referrer domain is stonewallscotland.org.uk set a sw_region cookie to be scotland
export function checkReferrer() {

    let referrer = getReferrerDomain();

    if (referrer === "stonewallscotland.org.uk") setReferrerCookie("sw_region", "scotland", 1);
    if (referrer === "stonewallcymru.org.uk") setReferrerCookie("sw_region", "wales", 1);

}

export function getReferrerCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}


const referrerCookie = getReferrerCookie("sw_region");
if (!referrerCookie) {
  const referrer = getReferrerDomain();
  // if (referrer === "stonewall-craft.ddev.site")
  //   setReferrerCookie("sw_region", "main", 1);
  if (referrer === "stonewallscotland.org.uk")
    setReferrerCookie("sw_region", "scotland", 1);
  if (referrer === "stonewallcymru.org.uk")
    setReferrerCookie("sw_region", "wales", 1);
}

document.addEventListener("DOMContentLoaded", function () {
    
    if (referrerCookie) {
        // get the #headerLogo elements
        let headerLogo = document.getElementById("headerLogo");
        let mobileHeaderLogo = document.getElementById("mobileHeaderLogo");

        // now prepare the correct src
        let newSrc = null
        // if (referrerCookie === "main") {
        //   newSrc = "/resources/logo.png?v=swap_" + referrerCookie;
        // }
        if (referrerCookie === "scotland") {
            newSrc = "/resources/rebrand-2021/new-stonewall-scotland-logo-white.png?v=swap_" + referrerCookie;
        }
        if (referrerCookie === "wales") {
            newSrc = "/resources/rebrand-2021/new-stonewall-cymru-logo-white.png?v=swap_" + referrerCookie;
        }
        // replace the header logo
        if (newSrc) {
          if (headerLogo) headerLogo.src = newSrc;
          if (mobileHeaderLogo) mobileHeaderLogo.src = newSrc;
        }
    }
});

export default { getReferrerDomain, getReferrerCookie, setReferrerCookie }